@import "@/styles/base/_colors";
@import "@/styles/base/_fonts";

.original-price {
  font-family: $font-medium;
  font-size: 16px;
  line-height: 24px;
  color: $color-slate;
}
.discountprice {
  :global {
    .price {
      display: flex;
    }
    .price-discount {
      font-family: $font-medium;
      font-size: 16px;
      line-height: 24px;
      color: $color-slate;
      display: flex;
    }
    .discount {
      font-family: $font-medium;
      font-size: 16px;
      line-height: 24px;
      color: $color-dark-gray;
      padding-left: 5px;
      text-decoration: line-through;
    }
  }
}
.original-price {
  font-family: $font-medium;
  font-size: 16px;
  line-height: 24px;
  color: $color-slate;
}
