@import "@/styles/base/_colors";
@import "@/styles/base/_fonts";

.leftside {
  :global {
    .productcard {
      .left-side {
        padding-right: 20px;
        @media screen and (max-width: 767px) {
          width: fit-content;
        }
        .upsell-modal-middle-border {
          border-right: 1px solid $color-light-gray;
        }
        .left-side--moblie {
          @media screen and (max-width: 767px) {
            display: flex;
          }
        }
        .prouct-info--mobile {
          @media screen and (max-width: 767px) {
            margin-top: 20px;
            margin-left: 40px;
          }
        }
        .head {
          font-family: $font-light;
          color: $color-slate;
          font-size: 26px;
          line-height: 34px;
          letter-spacing: -1.5px;
          @media screen and (max-width: 1200px) {
            font-size: 20px;
            line-height: 26px;
            letter-spacing: -1.5px;
          }
        }
        .image {
          height: 280px;
          width: 280px;
          margin-top: 40px;
          margin-bottom: 10px;
          @media screen and (max-width: 767px) {
            width: 157px;
            height: 157px;
          }
          @media screen and (min-width: 768px) and (max-width: 1023px) {
            height: 166px;
            width: 166px;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .product-name {
          font-family: $font-regular;
          font-size: 18px;
          line-height: 24px;
          letter-spacing: -0.82px;
          color: $color-slate;
          @media screen and (max-width: 1200px) {
            font-size: 16px;
            line-height: 22px;
            letter-spacing: -0.73px;
          }
        }
        .product-description {
          margin-top: 14px;
          font-family: $font-regular;
          font-size: 16px;
          line-height: 22px;
          letter-spacing: -0.55px;
          color: $color-dark-gray;
          margin-right: 50px;
          @media screen and (max-width: 767px) {
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.48px;
          }
        }
        .product-sku {
          font-family: $font-regular;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: -0.45px;
          color: $color-dark-gray;
          margin-right: 50px;
          margin-top: 14px;
          @media screen and (max-width: 767px) {
            font-size: 12px;
            line-height: 18px;
            letter-spacing: -0.39px;
          }
        }
        .product-price {
          font-family: $font-medium;
          font-size: 16px;
          line-height: 22px;
          letter-spacing: -0.8px;
          color: $color-slate;
          margin-right: 50px;
          margin-top: 14px;
          @media screen and (max-width: 767px) {
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.7px;
          }
        }
        .product-qty {
          font-family: $font-medium;
          font-size: 16px;
          line-height: 22px;
          letter-spacing: -0.8px;
          color: $color-slate;
          margin-right: 50px;
          margin-top: 14px;
          @media screen and (max-width: 767px) {
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.7px;
          }
        }
        .pdp-upsell {
          display: none;
        }
      }
      .left-side-pdp-required-item {
        padding-right: 65px;
        border-right: 1px solid $color-light-gray;
        @media screen and (max-width: 767px) {
          width: fit-content;
          border-bottom: 1px solid $color-light-gray;
          border-right: none;
          margin-bottom: 20px;
          padding-bottom: 20px;
        }

        .left-side--moblie {
          @media screen and (max-width: 767px) {
            display: flex;
          }
        }
        .prouct-info--mobile {
          @media screen and (max-width: 767px) {
            margin-top: 20px;
            margin-left: 40px;
          }
        }
        .head {
          font-family: $font-light;
          color: $color-slate;
          font-size: 32px;
          line-height: 40px;
          letter-spacing: -1.6px;
          @media screen and (max-width: 1200px) {
            font-size: 20px;
            line-height: 26px;
            letter-spacing: -1.5px;
          }
        }
        .image {
          height: 400px;
          width: 400px;
          margin-top: 30px;
          margin-bottom: 10px;
          @media screen and (max-width: 767px) {
            width: 157px;
            height: 157px;
          }
          @media screen and (min-width: 768px) and (max-width: 1023px) {
            height: 166px;
            width: 166px;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .product-name {
          font-family: $font-regular;
          font-size: 18px;
          line-height: 24px;
          letter-spacing: -0.82px;
          color: $color-slate;
          @media screen and (max-width: 1200px) {
            font-size: 16px;
            line-height: 22px;
            letter-spacing: -0.73px;
          }
        }
        .product-description {
          margin-top: 14px;
          font-family: $font-regular;
          font-size: 16px;
          line-height: 22px;
          letter-spacing: -0.55px;
          color: $color-dark-gray;
          margin-right: 50px;
          @media screen and (max-width: 767px) {
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.48px;
          }
        }
        .product-sku {
          font-family: $font-regular;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: -0.45px;
          color: $color-dark-gray;
          margin-right: 50px;
          margin-top: 14px;
          @media screen and (max-width: 767px) {
            font-size: 12px;
            line-height: 18px;
            letter-spacing: -0.39px;
          }
        }
        .product-price {
          font-family: $font-medium;
          font-size: 16px;
          line-height: 22px;
          letter-spacing: -0.8px;
          color: $color-slate;
          margin-right: 50px;
          margin-top: 14px;
          @media screen and (max-width: 767px) {
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.7px;
          }
        }
        .product-qty {
          font-family: $font-medium;
          font-size: 16px;
          line-height: 22px;
          letter-spacing: -0.8px;
          color: $color-slate;
          margin-right: 50px;
          margin-top: 14px;
          @media screen and (max-width: 767px) {
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.7px;
          }
        }
        .prouct-info--mobile {
          .product-qty {
            display: none;
          }
          .pdp-upsell {
            display: block;
          }
          .pdp-include-div {
            display: flex;
            padding-top: 20px;
          }
          .pdp-out-of-stock {
            font-family: $font-regular;
            font-size: 14px;
            line-height: 22px;
            letter-spacing: -0.33px;
            color: $color-dark-gray;
            padding-top: 10px;
          }
        }
      }
    }
  }
}
